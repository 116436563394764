(function (kino, $) {
	kino.productTray = function () {
		const $container = $('.carousel--product-kino');
		if (!$container.length) {
			return;
		}

		$container.each(function() {

			$(this).find('.image').matchHeight({ property: 'height' });


			let options;
			let $this = $(this);

			if ( (typeof $(this).data('autoplay-style') != 'undefined' && $(this).data('autoplay-style') === 'continuous') ) {
				options = {
					dots: false,
					autoplay: false,
					arrows: true,
					infinite: false,
					slidesToShow: (typeof $(this).data("center-mode") != 'undefined' && $(this).data("center-mode") === 'Enabled') ? 2 : 2,
					slidesToScroll: (typeof $(this).data("center-mode") != 'undefined' && $(this).data("center-mode") === 'Enabled') ? 2 : 2,
					autoplaySpeed: (typeof $(this).data("speed") != 'undefined' && $(this).data("speed")) ? $(this).data("speed") : 3000,
					mobileFirst:true,
					responsive: [
						{
							breakpoint:768,
							settings: {
								slidesToShow: 3,
								slidesToScroll: 3,
								autoplay:false,
								arrows: false,
								infinite:true,
							}
						},
						{
							breakpoint: 992,
							settings: {
								slidesToShow: 4,
								slidesToScroll: 4,
								autoplay:false,
								arrows: false,
								infinite:true,
							}
						},
						{
							breakpoint: 1200,
							settings: {
								slidesToShow: (typeof $(this).data("slides") != 'undefined' && $(this).data("slides")) ? $(this).data("slides") : 4,
								slidesToScroll: (typeof $(this).data("slides") != 'undefined' && $(this).data("slides")) ? ( $(this).data("slides") === 1 ? 1 : $(this).data("slides")-1 ) : 3,
								variableWidth: false,
								autoplay:false,
								arrows: false,
								infinite:true,
							}
						}
					]
				};
			} else {
				options = {
					dots: false,
					autoplay: (typeof $(this).data("autoplay") != 'undefined' && $(this).data("autoplay") === 'Enabled'),
					arrows: false,
					infinite: false,
					slidesToShow: (typeof $(this).data("slides-mobile") != 'undefined' && $(this).data("slides-mobile")) ? $(this).data("slides-mobile") : 1,
					slidesToScroll: (typeof $(this).data("slides-mobile") != 'undefined' && $(this).data("slides-mobile")) ? ( $(this).data("slides-mobile") === 1 ? 1 : $(this).data("slides-mobile") ) : 1,
					autoplaySpeed: (typeof $(this).data("speed") != 'undefined' && $(this).data("speed")) ? $(this).data("speed") : 3000,
					mobileFirst:true,
					centerMode:false,
					responsive: [
						{
							breakpoint:768,
							settings: {
								slidesToShow: 3,
								slidesToScroll: 3,
								arrows: (!(typeof $(this).data("controls") != 'undefined' && $(this).data("controls") === 'Disabled')),
								centerMode: false,
								infinite: (!(typeof $(this).data("infinite_scroll") != 'undefined' && $(this).data("infinite_scroll") === 'Disabled')),
							}
						},
						{
							breakpoint: 992,
							settings: {
								slidesToShow: 4,
								slidesToScroll: 4,
								centerMode: false,
								arrows: (!(typeof $(this).data("controls") != 'undefined' && $(this).data("controls") === 'Disabled')),
								infinite: (!(typeof $(this).data("infinite_scroll") != 'undefined' && $(this).data("infinite_scroll") === 'Disabled')),
							}
						},
						{
							breakpoint: 1200,
							settings: {
								slidesToShow: (typeof $(this).data("slides") != 'undefined' && $(this).data("slides")) ? $(this).data("slides") : 4,
								slidesToScroll: (typeof $(this).data("slides") != 'undefined' && $(this).data("slides")) ? ( $(this).data("slides") === 1 ? 1 : $(this).data("slides")-1 ) : 3,
								variableWidth: (!(typeof $(this).data("variable-width") != 'undefined' && ($(this).data("variable-width") === 'Disabled' || $(this).data("variable-width") === 'false') )),
								centerMode: false,
								arrows: (!(typeof $(this).data("controls") != 'undefined' && $(this).data("controls") === 'Disabled')),
								infinite: (!(typeof $(this).data("infinite_scroll") != 'undefined' && $(this).data("infinite_scroll") === 'Disabled')),
							}
						}
					]
				};
			}

			function updateSlides(slide) {

				const activeSlides = $(slide);
				const firstSlide = activeSlides[0];
				const lastSlide = activeSlides[activeSlides.length-1];

				$(firstSlide).addClass('first-slide');
				$(lastSlide).addClass('last-slide');
			}

			$this.on('init', function (event, slick) {

				$(this).parent().fadeIn(6000);

				let sliderCount = slick.slideCount;
				let imgHeight = $(this).find('.carousel__item.slick-current img').outerHeight();

				if ($(this).hasClass('carousel--expand')) {

					let activeSlide = $(this).find('.slick-active');
					updateSlides(activeSlide);

					if (sliderCount <= 1) {
						$(activeSlide).parent().css({'min-height': imgHeight});
						$(activeSlide).addClass('single-slide');
					}
				}


			}).slick(options);

			if ( $this.hasClass('carousel--expand') ) {

				$this.on('afterChange', function(event, slick, currentSlide) {
					let activeSlide = $(this).find('.slick-active');
					updateSlides(activeSlide);
					$(this).find('.slick-current').removeClass('last-slide');
				});
			}




		});
	};
	kino.textCarousel = function() {
		var $container = $('.kino-carousel--text');
		if (!$container.length) {
			return;
		}
		$container.each(function() {

			$(this).on('init', function (slick) {
				$(this).parent().fadeIn(6000);
			}).slick({
				slidesToShow: (typeof $(this).data("slides") != 'undefined' && $(this).data("slides")) ? $(this).data("slides") : 4,
				slidesToScroll: (typeof $(this).data("slides") != 'undefined' && $(this).data("slides")) ? $(this).data("slides") : 4,
				dots: false,
				infinite: (typeof $(this).data("infinite_scroll") != 'undefined' && $(this).data("infinite_scroll") === 'Enabled'),
				speed: 500,
				arrows: (!(typeof $(this).data("controls") != 'undefined' && $(this).data("controls") === 'Disabled')),
				autoplay: (typeof $(this).data("autoplay") != 'undefined' && $(this).data("autoplay") === 'Enabled'),
				autoplaySpeed: (typeof $(this).data("speed") != 'undefined' && $(this).data("speed")) ? $(this).data("speed") : 3000,
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 3,
							infinite: true,
							dots: true
						}
					},
					{
						breakpoint: 600,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2
						}
					},
					{
						breakpoint: 480,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							arrows:false,
							centerMode:true,
							infinite: true
						}
					}
				]
			});
		});
	};
}(window.kino = window.kino || {}, jQuery));
