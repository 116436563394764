/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Kino = {
		// All pages
		'common': {
			appendRFKSearchMobile: function() {

				//get DOM elements
				const rfkInput = document.querySelector('input[data-rfkid]');
				const headerSearch = document.getElementById('headerSearch');
				const headerMQL = window.matchMedia('(max-width: 991px)');
				const header = document.querySelector('.header--kino');
				const navbarMenu = document.querySelector('.header--kino .navbar-nav');

				//append Reflektion search results to header
				rfkInput.addEventListener('click',function(e) {
					const rfkSearchContainer = document.getElementById('rfk_search_container');
					header.append(rfkSearchContainer);
				});

				function headerBrowserChange(e) {

					if (e.matches) {
						//place search form after collapsed navbar
						header.append(headerSearch);
					} else {
						//place search form after main navigation
						navbarMenu.after(headerSearch);
					}
				}

				//headerBrowserChange(headerMQL);

				//listen for browser change
				//headerMQL.addEventListener('change', headerBrowserChange);
			},
			triggerRFKEvents: function() {
				$('body').on('click', '[data-rfk-beacon]', function() {
					const productSku = $(this).data('product-sku');

					window.rfk = window.rfk || [];

					rfk.push(["trackEvent", {
						"type": "a2c",
						"name": "pdp",
						"value":{
							"products":[{
								"sku": productSku

							}]
						}
					}]);
				});
			},
			init: function() {
				// JavaScript to be fired on all pages
				window.kino.productTray();
				window.kino.textCarousel();
				Kino.common.appendRFKSearchMobile();
				Kino.common.triggerRFKEvents();
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Kino;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
